var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('WithReport',{attrs:{"id":_vm.reportId},scopedSlots:_vm._u([{key:"default",fn:function({ report, draft, isReportLoading, reportError }){return _c('article',{},[(_vm.userHasViewPermission)?_c('WithLocation',{attrs:{"id":_vm.locationId},scopedSlots:_vm._u([{key:"default",fn:function({
            location,
            isLocationLoading,
            address,
            metaFields,
            locationError,
          }){return _c('div',{},[(!locationError && report)?[(!isReportLoading)?_c('DailyReportBreadcrumbs',{attrs:{"report":report,"location":location}}):_vm._e(),(!isReportLoading)?_c('VLayout',{class:[
                'status-indicator',
                `status--${report.REPORT_STATUS || 'default'}`,
              ],attrs:{"align-center":""}},[_c('VFlex',[_vm._v("Report Status : "+_vm._s(report.REPORT_STATUS || 'No Report Status'))])],1):_vm._e(),(!isLocationLoading)?_c('BaseLocationMeta',{attrs:{"fields":metaFields,"job":location ? location.JOB[0] : null}}):_vm._e()]:_vm._e(),(isReportLoading || isLocationLoading)?_c('BaseSpinner'):(reportError || locationError)?_c('VLayout',{attrs:{"pa-4":"","align-center":""}},[_c('VFlex',{staticClass:"mx-auto",attrs:{"xs12":"","sm9":""}},[_c('h3',[_vm._v("Sorry but this report could not be fully loaded. It may be an issue with Knack so please try reloading the page.")]),_c('BaseButton',{attrs:{"color":"blue white--text"},on:{"click":_vm.reload}},[_vm._v("Click to Reload")])],1)],1):(!report)?_c('VLayout',{attrs:{"pa-4":"","align-center":""}},[_c('VFlex',[_c('h3',[_vm._v("No report found.")]),_c('p',[_vm._v("ID : "+_vm._s(_vm.reportId))]),_c('BaseButton',{attrs:{"to":`/locations/${_vm.locationId}`}},[_vm._v("View Location")])],1)],1):_c('div',[_c('DailyReport',{attrs:{"report":report},on:{"dirtyUpdate":_vm.updateDirtiness}})],1)],2)}}],null,true)}):_vm._e()],1)}}])}),_c('AutosaveSnackbar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }