<template>
  <Layout>
    <WithReport :id="reportId">
      <article slot-scope="{ report, draft, isReportLoading, reportError }">
        <WithLocation :id="locationId" v-if="userHasViewPermission">
          <div
            slot-scope="{
              location,
              isLocationLoading,
              address,
              metaFields,
              locationError,
            }"
          >
            <template v-if="!locationError && report">
              <DailyReportBreadcrumbs
                v-if="!isReportLoading"
                :report="report"
                :location="location"
              />
              <VLayout
                v-if="!isReportLoading"
                :class="[
                  'status-indicator',
                  `status--${report.REPORT_STATUS || 'default'}`,
                ]"
                align-center
                ><VFlex
                  >Report Status :
                  {{ report.REPORT_STATUS || 'No Report Status' }}</VFlex
                ></VLayout
              >
              <BaseLocationMeta
                v-if="!isLocationLoading"
                :fields="metaFields"
                :job="location ? location.JOB[0] : null"
              />
            </template>
            <BaseSpinner v-if="isReportLoading || isLocationLoading" />
            <VLayout v-else-if="reportError || locationError" pa-4 align-center>
              <VFlex xs12 sm9 class="mx-auto">
                <h3
                  >Sorry but this report could not be fully loaded. It may be an
                  issue with Knack so please try reloading the page.</h3
                >
                <BaseButton color="blue white--text" @click="reload"
                  >Click to Reload</BaseButton
                >
              </VFlex>
            </VLayout>
            <VLayout v-else-if="!report" pa-4 align-center>
              <VFlex>
                <h3>No report found.</h3>
                <p>ID : {{ reportId }}</p>
                <BaseButton :to="`/locations/${locationId}`"
                  >View Location</BaseButton
                >
              </VFlex>
            </VLayout>
            <div v-else>
              <DailyReport :report="report" @dirtyUpdate="updateDirtiness" />
            </div>
          </div>
        </WithLocation>
      </article>
    </WithReport>
    <AutosaveSnackbar />
  </Layout>
</template>

<script>
// Q: Confused about including this file in itself as a component
import * as appConfig from '@src/app.config'
import Layout from '@layouts/breadcrumbLayout'
import DailyReport from '@dailyReport/DailyReport'
import WithReport from '@components/WithReport'
import AutosaveSnackbar from '@dailyReport/AutosaveSnackbar'
import DailyReportBreadcrumbs from '@components/DailyReportBreadcrumbs'
import WithLocation from '@dataProviders/WithLocation'

import router from '@router'
import { mapGetters } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'DailyReportView',
  components: {
    Layout,
    DailyReport,
    WithReport,
    WithLocation,
    DailyReportBreadcrumbs,
    AutosaveSnackbar,
  },
  metaInfo() {
    let report = this.getReport(this.reportId)
    let title = report.DAILY_REPORT_NAME

    // let loc = this.getLocation(this.locationId)
    // let reportDate = report.REPORT_DATE ? report.REPORT_DATE.date : ''
    // let title = `${reportDate} - ${loc.JOB_NUMBER__IDENTIFIER}`
    return {
      title: title,
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
    reportId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getReport',
      'getLocation',
      'getUserEmail',
      'isProjMgr',
      'isDispatcher',
    ]),
    ...mapFields(['isReportDirty']),
    userHasViewPermission() {
      if (this.isProjMgr || this.isDispatcher) {
        return true
      }
      let currentUserEmail = this.getUserEmail ?? ''
      let reportForemanEmail =
        this.getReport(this.reportId).FOREMANS_EMAIL ?? ''
      return currentUserEmail.toLowerCase() === reportForemanEmail.toLowerCase()
    },
  },
  methods: {
    updateDirtiness(val) {
      this.isReportDirty = val
    }, // updateDirtiness
    reload() {
      window.location.reload()
    }, // reload
  },
  watch: {
    userHasViewPermission: {
      handler(val) {
        if (!val) {
          this.$notify({
            title: 'You do not have permission to view this report',
            type: 'warn',
          })
          router.replace({
            name: 'Location',
            params: { id: this.locationId },
          })
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const answer = this.isReportDirty
      ? window.confirm('Do you really want to leave? you have unsaved changes!')
      : true
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.sub-header-nav-link {
  padding: 5px 10px;
  font-size: 0.9rem;
  color: white;
  text-transform: uppercase;
  border: 1px solid white;
  border-radius: 11px;
  transition: color 0.3s ease, background-color 0.3 ease;
  &:hover {
    color: black;
    background-color: white;
  }
}

.report-data {
  display: inline-block;
  padding: 10px;
}

.daily-report {
  // &.status-Completed {
  //   background-color:$green;
  // }

  > .container,
  .meta--wrap > .container {
    @media #{$gt_768} {
      width: 95%;
      max-width: 1440px;
    }
  }

  .meta--wrap > .container {
    @media #{$lt_1024} {
      width: 100%;
    }
  }
}

.quick-links-title {
  padding-right: 10px;
  text-align: right;
}
.quick-links {
  align-items: center;
  justify-content: center;

  @media #{$lt_980} {
    width: 100%;
  }

  @media (max-width: 800px) {
    .quick-links-title {
      display: none;
    }
  }
  > .flex-item {
    flex: 0 0 auto;
    margin-right: 7px;
    margin-bottom: 5px;
  }
}

.status-indicator {
  width: 100%;
  height: 25px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  &.status--Setup {
    background-color: $orange;
  }
  &.status--Completed {
    background-color: $green;
  }
  &.status--Locked {
    background-color: $blue;
  }
  &.status--Scheduled {
    color: black;
    background-color: $teal;
  }
  &.status--default {
    background-color: gray;
  }
}

.progress {
  background-color: $gray-dark;
  .determinate {
    background-color: $teal;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.3s;
}
</style>

<style lang="scss">
@media print {
  // stylelint-disable-next-line
  #daily-report-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    .hint,
    label {
      font-size: 16px !important;
      font-weight: bold !important;
    }
    .flex .hint {
      // display: inline-block;
      width: 120px;
    }
  }

  .meta--wrap.container {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 12px;
  }

  //
  // Daily Cards
  //
  .daily-card {
    .daily-card--header {
      padding: 0;
      margin-bottom: 7px;
      font-size: 1.3rem;
    }
    .list--wrap {
      padding: 0;
    }
    .list--header {
      padding: 0;
    }
    .col-header {
      font-weight: bold;
    }
    .daily-list-item {
      padding: 0.3rem 0 0;
      margin: 0 !important;
      background: none !important;
      border-color: $gray-body;
      border-style: dashed;
      border-width: 1px 0 0 0;
      page-break-inside: avoid;
      &:last-child {
        padding-bottom: 0.3rem;
        border-bottom: 1px dashed $gray-body;
      }

      @media print {
        padding: 0;
      }
    }
    .list-item-field--wrap {
      .list-item-field {
        border: 0;
        .btn-with-icon {
          font-size: 0.9rem;
        }
      }
      .printable-checkbox {
        .material-icons {
          font-size: 1.4rem;
        }
      }
      &.bold {
        font-weight: normal;
      }
      &.editable .list-item-field:not(.type-checkbox) {
        padding: 0;
        text-transform: none;
      }
    }
  }
} // @media print
</style>
